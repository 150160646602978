import { createContext, Show, useContext } from 'solid-js';
import { createStore } from 'solid-js/store';
import { Icon } from '@troon/icons';
import type { Component, JSXElement } from 'solid-js';

type Flow = 'login' | 'magicLink' | 'forgotPassword' | 'register' | 'registerInline';

export const flows: Record<Flow, Record<string, { heading: Component | JSXElement; description?: Component }>> = {
	login: {
		'/auth': {
			heading: () => (
				<span class="flex flex-col gap-8">
					<Icon name="logo-square" class="size-12 text-brand" />
					Welcome to Troon
				</span>
			),
			description: Welcome,
		},
		'/auth/login': { heading: 'Welcome back', description: WelcomeBack },
	},

	magicLink: {
		'/auth': { heading: 'Welcome to Troon', description: Welcome },
		'/auth/login': { heading: 'Welcome back', description: WelcomeBack },
		'/auth/magic-link': { heading: 'Check your email', description: MagicLink },
	},

	forgotPassword: {
		'/auth': { heading: 'Welcome to Troon', description: Welcome },
		'/auth/login': { heading: 'Welcome back', description: WelcomeBack },
		'/auth/reset': { heading: ResetPassword, description: ForgotCode },
		'/auth/reset/password': { heading: SetPassword, description: NewPassword },
	},

	register: {
		'/auth': { heading: 'Welcome to Troon', description: Welcome },
		'/auth/join/password': { heading: 'Create Your Password', description: CreatePassword },
		'/auth/join/name': { heading: 'What’s Your Name?', description: Name },
		'/auth/join/postal-code': { heading: 'What’s Your Postal Code?', description: PostalCode },
	},

	registerInline: {
		'/auth': { heading: 'Log in or sign up' },
		'/auth/join': { heading: 'Sign up' },
	},
} as const;

function ResetPassword() {
	const [store] = useAuthStore();
	return (
		<Show when={!store.data.firstTimeUser} fallback="Set your password">
			Reset your password
		</Show>
	);
}

function SetPassword() {
	const [store] = useAuthStore();
	return (
		<Show when={!store.data.firstTimeUser} fallback="Set your password">
			Change password
		</Show>
	);
}

function Welcome() {
	return (
		<p>
			Enter your <b>email</b> or <b>Troon Rewards ID</b> to log into your account. If you don’t have one, you’ll be
			prompted to create one for free.
		</p>
	);
}

function CreatePassword() {
	return <p>Keep your account secure with a strong password. Review the password requirements below.</p>;
}

function WelcomeBack() {
	const [store] = useAuthStore();
	return (
		<p>
			We found an existing Troon Rewards account for <b>{store.data.email}</b>. Enter your password to log in and access
			your account.
		</p>
	);
}

function MagicLink() {
	const [store] = useAuthStore();
	return (
		<p>
			We sent a magic link to{' '}
			<Show when={!store.data.email?.includes('@')} fallback={<b>{store.data.email}</b>}>
				the email address associated to <b>{store.data.email}</b>
			</Show>
			. Click on the link in the email to log in, or enter the code below.
		</p>
	);
}

function ForgotCode() {
	const [store] = useAuthStore();
	return (
		<p>
			Check{' '}
			<Show
				when={store.data.email?.includes('@')}
				fallback={<>the email address associated with your Troon Rewards account</>}
			>
				your email
			</Show>{' '}
			for a confirmation code.
		</p>
	);
}

function Name() {
	return <p>Let us know who you are.</p>;
}

function NewPassword() {
	return <p>Create a password with at least 8 letters and numbers.</p>;
}

function PostalCode() {
	return <p>Earn special promotions from Troon courses in your area.</p>;
}

export type FlowStep = keyof (typeof flows)[Flow];

export type StoreState = {
	data: Record<string, string>;
	flow: Flow;
	highestStepIndex: number;
	redirect: string;
	step: FlowStep;
};

export const defaultStore: StoreState = {
	data: {},
	flow: 'login',
	highestStepIndex: 0,
	redirect: '/',
	step: Object.keys(flows.login)[0] as FlowStep,
};

type Store = ReturnType<typeof createStore<StoreState>>;
const [authStore, setAuthStore] = createStore<StoreState>(defaultStore);
export const AuthFlowContext = createContext<Store>([authStore, setAuthStore]);

export function useAuthStore() {
	return useContext(AuthFlowContext);
}

export function flowFromStep(step: FlowStep): Flow | undefined {
	const found = Object.entries(flows).find(([, steps]) => Object.keys(steps).includes(step));
	if (found) {
		return found[0] as Flow;
	}
	return 'login';
}
